<template>
	<div class="q-pa-sm">
		<sftp-template-settings
			:template="template"
			@update="updateTemplateSettings"
		/>
		<import-settings
			:import_settings="template_settings"
			:file="
				template.file_headers
					? {
							file_headers: template.file_headers.headers,
							file_data: template.file_data,
							file_name: template.file_name,
					}
					: null
			"
			:table_column_options="selection_list[index]"
			:delete_button="true"
			@update="updateTemplateSettings"
			@fileUpdate="fileUpdate"
			@delete="delete_dialog = true"
			@save="handleSaveTemplate"
		/>
		<q-dialog v-model="delete_dialog">
			<q-card>
				<q-card-section class="text-center">
					<span
						>Are you sure you want to delete the
						<b>{{ template.template_settings.template_name.name }}?</b></span
					>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn
						flat
						label="Delete"
						color="primary"
						@click="confirmDeleteField"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SftpTemplateSettings from '@/components/datasets/edit/sftp/SftpTemplateSettings.vue'
import ImportSettings from '@/components/datasets/edit/sftp/ImportSettings.vue'
import  helpers from '@/utils/helpers.js'

export default {
	name: 'SftpTemplate',
	components: {
		SftpTemplateSettings,
		ImportSettings,
	},
	props: {
		template: {
			required: true,
			type: Object,
		},
		index: Number,
	},
	data() {
		return {
			delete_dialog: false,
			import_rules_obj: {},
			file: null,
		}
	},
	computed: {
		...mapGetters(['selection_list']),
		template_settings() {
			let template_settings = JSON.parse(
				JSON.stringify(this.template.template_settings)
			)
			let request_data = JSON.parse(
				JSON.stringify(this.template.template_settings.request_data)
			)
			template_settings = { ...template_settings, ...request_data }
			delete template_settings.request_data
			return template_settings
		},
	},
	methods: {
		...mapActions([
			'getSavedTemplate',
			'saveTemplate',
			'analyzeFile',
			'deleteTemplate',
			'updateSelectionList',
			'fetchUser',
		]),
		async confirmDeleteField() {
			await this.deleteTemplate(this.index)
			this.delete_dialog = false
			this.$q.notify('The template was removed successfully.')
		},
		async handleSaveTemplate(rules) {
			let form_data = helpers.buildTemplateFormData({
				template_settings: {
					...this.template.template_settings,
					...this.template.template_settings.request_data,
				},
				rules: rules,
				index: this.index,
				file: this.file
			})
			const response = await this.saveTemplate(form_data)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(response)
			await this.fetchUser()
		},
		updateTemplateSettings(settings) {
			let template = JSON.parse(JSON.stringify(this.template))
			let request_data = {
				allow_new_field_options_to_be_created: settings.allow_new_field_options_to_be_created,
				keep_admins_from_demoting: settings.keep_admins_from_demoting,
				user: settings.user,
			}
			delete settings.allow_new_field_options_to_be_created
			delete settings.keep_admins_from_demoting
			delete settings.user
			settings.request_data = request_data
			template.template_settings = settings
			template.template_settings.rules = null
			this.$emit('update', template)

			this.updateSelectionList({
				index: this.index,
				import_type: template.template_settings.import_type,
				column: template.template_settings.import_by_column,
				cg: template.template_settings.customer_group,
				external_id: template.template_settings.external_id,
				gpg_encrypted: template.template_settings.gpg_encrypted,
			})
		},
		async fileUpdate(val) {
			this.file = val
			await this.analyzeFile({ val, index: this.index })
		},
	},
}
</script>

<style lang="scss" scoped></style>
