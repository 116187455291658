var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.customer_group_permissions.sftp_imports)?_c('div',{staticClass:"flex column text-left"},[(_vm.is_ready)?_c('div',{staticStyle:{"max-width":"100%"}},[_c('h1',{staticClass:"setting-title"},[_vm._v("SFTP import configuration: "+_vm._s(_vm.cg.name))]),_c('sftp-instructions'),_c('q-list',{staticClass:"rounded-borders",attrs:{"bordered":""}},_vm._l((_vm.templates_data),function(template,index){return _c('q-expansion-item',{key:template.file_name,attrs:{"expand-separator":"","icon":"file_present","label":`Template ${template.template_settings.template_name.label}`}},[_c('sftp-template',{attrs:{"template":template,"index":index},on:{"update":(data) => _vm.updateTemplate(index, data)}})],1)}),1)],1):_vm._e(),_c('div',{staticStyle:{"position":"sticky","bottom":"20px","width":"fit-content","margin":"0 auto","margin-top":"8px"}},[(_vm.is_ready && _vm.templates_data.length < 5)?_c('div',[(_vm.$q.screen.gt.sm)?_c('q-btn',{attrs:{"to":{
					name: 'Sftp Add',
					params: { charIndex: _vm.templates_data.length },
				},"label":"Add new template","rounded":"","icon":"add","color":"primary","padding":"md lg"}}):_c('q-btn',{attrs:{"to":{
					name: 'Sftp Add',
					params: { charIndex: _vm.templates_data.length },
				},"round":"","icon":"add","color":"primary","size":"lg"}})],1):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }