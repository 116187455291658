<template>
	<div
		class="flex column text-left"
		v-if="customer_group_permissions.sftp_imports"
	>
		<div v-if="is_ready" style="max-width: 100%">
			<h1 class="setting-title">SFTP import configuration: {{ cg.name }}</h1>
			<sftp-instructions />
			<q-list bordered class="rounded-borders">
				<q-expansion-item
					v-for="(template, index) in templates_data"
					:key="template.file_name"
					expand-separator
					icon="file_present"
					:label="`Template ${template.template_settings.template_name.label}`"
				>
					<sftp-template
						:template="template"
						:index="index"
						@update="(data) => updateTemplate(index, data)"
					/>
				</q-expansion-item>
			</q-list>
		</div>
		<div
			style="position: sticky; bottom: 20px; width: fit-content; margin: 0 auto; margin-top: 8px;"
		>
			<div v-if="is_ready && templates_data.length < 5">
				<q-btn
					v-if="$q.screen.gt.sm"
					:to="{
						name: 'Sftp Add',
						params: { charIndex: templates_data.length },
					}"
					label="Add new template"
					rounded
					icon="add"
					color="primary"
					padding="md lg"
				/>
				<q-btn
					v-else
					:to="{
						name: 'Sftp Add',
						params: { charIndex: templates_data.length },
					}"
					round
					icon="add"
					color="primary"
					size="lg"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SftpTemplate from '@/components/datasets/edit/sftp/SftpTemplate.vue'
import SftpInstructions from '@/components/datasets/edit/sftp/SftpInstructions.vue'
export default {
	name: 'List',
	components: {
		SftpTemplate,
		SftpInstructions,
	},
	computed: {
		...mapGetters(['customer_group_permissions', 'sftp_templates']),
		cg() {
			return this.$store.getters.customer_groups.filter(
				(cg) => cg.id == this.$route.params.id
			)[0]
		},
	},
	data() {
		return {
			is_ready: false,
			templates_data: [],
		}
	},
	methods: {
		...mapActions(['getSavedTemplate', 'updateSftpTemplates']),
		updateTemplate(index, data) {
			this.templates_data[index] = data
			this.updateSftpTemplates(JSON.parse(JSON.stringify(this.templates_data)))
		},
	},
	async mounted() {
		this.$q.loading.show({
			delay: 400,
		})
		await this.getSavedTemplate(this.$route.params.id)
		this.templates_data = JSON.parse(JSON.stringify(this.sftp_templates))
		this.is_ready = true
		this.$q.loading.hide()
	},
	watch: {
		sftp_templates: {
			handler(val) {
				this.templates_data = JSON.parse(JSON.stringify(val))
			},
			deep: true,
		},
	},
}
</script>
