<template>
	<div class="q-my-sm">
		<q-expansion-item
			v-model="expanded"
			label="SFTP Instructions"
			class="text-left rounded-borders sftp-instructions-wrapper"
		>
			<div class="q-pa-md">
				<div>
					<p>
						In order to use the SFTP (push) data import feature, you will need the following:
					</p>
					<p>
						<strong>1)</strong> An SFTP import template within your Alert Cascade service. You can create and edit your template below.
					</p>

					<p>
						<strong>2)</strong> Valid SFTP client credentials to connect to the Alert Cascade SFTP server and upload your import file. Your SFTP client credentials are:
					</p>
					<q-input
						v-model="hostname"
						outlined
						readonly
						hint="Hostname"
						class="q-my-md"
					>
					</q-input>
					<q-input
						v-model="sftp_username"
						outlined
						readonly
						:type="isUsername ? 'password' : 'text'"
						hint="Username"
						class="q-my-md"
					>
						<template v-slot:append>
							<q-icon
								:name="isUsername ? 'visibility_off' : 'visibility'"
								class="cursor-pointer"
								@click="hide('isUsername')"
							/>
						</template>
					</q-input>
					<q-input
						v-model="sftp_password"
						outlined
						readonly
						:type="isPwd ? 'password' : 'text'"
						hint="Password"
						class="q-my-md"
					>
						<template v-slot:append>
							<q-icon
								:name="isPwd ? 'visibility_off' : 'visibility'"
								class="cursor-pointer"
								@click="hide('isPwd')"
							/>
						</template>
					</q-input>
				</div>
				<div class="q-mt-sm">
					<p>
						Once you have established an SFTP connection through an SFTP client of your choice, upload your file to the "unprocessed data" folder. This is
						the only folder you will have the ability to upload to. All other folders are view only.
					</p>
					<p>
						If you wish to encrypt your file using PGP encryption, you can specify this within your SFTP import template. Once you do this, you will be
						provided with a downloadable copy of our public key to allow you to encrypt your file prior to upload.
					</p>
				</div>
			</div>
		</q-expansion-item>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatasetsAPI from '@/services/api/Datasets.js'

export default {
	name: 'SftpInstructions',
	data() {
		return {
			isPwd: true,
			isUsername: true,
			sftp_username: null,
			sftp_password: null,
			hostname: null,
			expanded: false,
		}
	},
	computed: {
		...mapGetters(['sftp_settings']),
	},
	methods: {
		hide(attr) {
			this[attr] = !this[attr]
			setTimeout(() => {
				this[attr] = !this[attr]
			}, 10000);
		}
	},
	async created() {
		const response = await DatasetsAPI.getSftpServerUrl();
		const url = new URL(response.url);
		this.hostname = url.hostname;
		this.sftp_username = this.sftp_settings.sftp_username;
		this.sftp_password = this.sftp_settings.sftp_password;
	},
}
</script>

<style lang="scss" scoped>
.sftp-instructions-wrapper {
	text-align: left;
	width: 100%;
	border: 1px solid $grey-4;
}
</style>
