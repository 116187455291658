var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"q-pa-sm"},[_c('sftp-template-settings',{attrs:{"template":_vm.template},on:{"update":_vm.updateTemplateSettings}}),_c('import-settings',{attrs:{"import_settings":_vm.template_settings,"file":_vm.template.file_headers
				? {
						file_headers: _vm.template.file_headers.headers,
						file_data: _vm.template.file_data,
						file_name: _vm.template.file_name,
				}
				: null,"table_column_options":_vm.selection_list[_vm.index],"delete_button":true},on:{"update":_vm.updateTemplateSettings,"fileUpdate":_vm.fileUpdate,"delete":function($event){_vm.delete_dialog = true},"save":_vm.handleSaveTemplate}}),_c('q-dialog',{model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('q-card',[_c('q-card-section',{staticClass:"text-center"},[_c('span',[_vm._v("Are you sure you want to delete the "),_c('b',[_vm._v(_vm._s(_vm.template.template_settings.template_name.name)+"?")])])]),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"flat":"","label":"Cancel","color":"primary"}}),_c('q-btn',{attrs:{"flat":"","label":"Delete","color":"primary"},on:{"click":_vm.confirmDeleteField}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }